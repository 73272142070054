@charset "UTF-8";
@import "icon";
// SASS関数を上書きする関数。CSS関数を使えるようにするため。
@function min($values...) {
  @return unquote('min(#{$values})');
}
@function max($values...) {
  @return unquote('max(#{$values})');
}
$fwBlack: 900;
$ffOswald: 'Oswald', sans-serif;
// #clearfix
@mixin clearfix {
  &:after {
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    content: '.';
  }
}

// web fontを使う場合
@mixin icon_ {
  font-family: "icomoon" !important;
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  line-height: 1;

  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// sp
@mixin sp {
  @media (max-width: 428px) {
    @content;
  }
}

@mixin pc {
  @media (min-width: 429px) {
    @content;
  }
}
