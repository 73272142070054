@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900|Oswald:300,400&display=swap");
html {
  scroll-behavior: smooth;
}

:root {
  --navigation_width_pc: 75px;
  --navigation_height_sp: 60px;
}

*::selection {
  background: #39A796;
  color: #fff;
}

body {
  font-family: 'Noto Sans JP', sans-serif;
  overflow: auto;
  background: #fff;
  min-width: 1280px;
}

body.is_loading {
  overflow: hidden;
}

@media (max-width: 428px) {
  body {
    min-width: 100%;
  }
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.pc {
  display: block;
}

@media (max-width: 428px) {
  .pc {
    display: none;
  }
}

.sp {
  display: none;
}

@media (max-width: 428px) {
  .sp {
    display: block;
  }
}

.post-edit-link {
  line-height: 1.5;
}

.cmn-contents {
  font-family: 'Noto Sans JP', sans-serif;
  color: #363636;
}

.cmn-contents--copy {
  background: #213326;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cmn-contents--top_button {
  position: fixed;
  right: 20px;
  bottom: 0;
  width: 80px;
  height: 80px;
  border: 2px solid #fff;
  border-radius: 10px;
  background: #363636;
  z-index: 10;
  opacity: 0;
  transition: 0.9s cubic-bezier(0.23, 1, 0.32, 1);
}

.cmn-contents--top_button:before {
  content: '';
  display: block;
  position: absolute;
  left: calc(50% - 2.5px);
  top: calc(50% - 17.5px);
  width: 5px;
  height: 35px;
  background: #fff;
}

.cmn-contents--top_button:after {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-top: 5px solid #fff;
  border-right: 5px solid #fff;
  transform: rotate(-45deg);
  left: calc(50% - 12px);
  top: 25px;
}

.cmn-contents--top_button.is_show {
  bottom: 20px;
  opacity: 1;
}

@media (max-width: 428px) {
  .cmn-contents--top_button {
    width: 50px;
    height: 50px;
    right: 10px;
    bottom: 10px;
  }
  .cmn-contents--top_button:before {
    left: calc(50% - 1px);
    top: calc(50% - 10px);
    width: 2px;
    height: 20px;
    background: #fff;
  }
  .cmn-contents--top_button:after {
    width: 10px;
    height: 10px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(-45deg);
    left: calc(50% - 6px);
    top: 15px;
  }
}

.cmn-gl_nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: #213326;
  width: 75px;
  z-index: 10;
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (min-width: 429px) {
  .cmn-gl_nav {
    left: -80px;
  }
}

@media (max-width: 428px) {
  .cmn-gl_nav {
    width: 100%;
    height: 60px;
  }
}

@media (min-width: 429px) {
  .cmn-gl_nav.is_animate_show {
    animation: slideRightNav 0.9s 0.95s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  }
}

@media (min-width: 429px) {
  .cmn-gl_nav.show_on_start {
    left: 0;
  }
}

@media (min-width: 429px) {
  .cmn-gl_nav.is_show {
    width: 300px;
  }
}

@media (max-width: 428px) {
  .cmn-gl_nav.is_show {
    height: 350px;
  }
}

@media (min-width: 429px) {
  .cmn-gl_nav.is_show .cmn-gl_nav--menu_button:before {
    transform: rotate(-212deg) skew(0, -20deg);
    left: 8px;
  }
}

@media (max-width: 428px) {
  .cmn-gl_nav.is_show .cmn-gl_nav--menu_button:before {
    transform: rotate(-126deg) skew(0, -20deg);
    top: 9px;
  }
}

.cmn-gl_nav.is_show .cmn-gl_nav--content {
  opacity: 1;
  pointer-events: auto;
}

.cmn-gl_nav.is_show .cmn-gl_nav--link {
  pointer-events: auto;
  opacity: 1;
  left: 0;
}

@media (max-width: 428px) {
  .cmn-gl_nav.background_gray_sp {
    background-color: #dddddd;
  }
}

.cmn-gl_nav--menu_button {
  font-family: 'Oswald', sans-serif;
  font-size: 14px;
  font-weight: 100;
  text-decoration: none;
  color: #fff;
  position: absolute;
  right: 25px;
  z-index: 10;
}

.cmn-gl_nav--menu_button:before {
  content: '';
  display: block;
  height: 25px;
  width: 25px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(-32deg) skew(0deg, -20deg);
  position: relative;
  left: -5px;
  margin: 9px 0 20px 0;
  transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (min-width: 429px) {
  .cmn-gl_nav--menu_button {
    top: calc(50% - 35px);
  }
}

@media (max-width: 428px) {
  .cmn-gl_nav--menu_button {
    width: 70px;
    text-align: center;
    letter-spacing: 2px;
    right: 0;
    height: 60px;
    padding: 5px 0 0 0;
    box-sizing: border-box;
  }
  .background_gray_sp .cmn-gl_nav--menu_button {
    color: #000000;
  }
  .cmn-gl_nav--menu_button:before {
    transform: rotate(54deg) skew(0deg, -20deg);
    left: 26px;
    width: 15px;
    height: 15px;
    margin: 0 0 10px 0;
  }
  .background_gray_sp .cmn-gl_nav--menu_button:before {
    border-color: #000000;
  }
}

.cmn-gl_nav--corp_name {
  color: #fff;
  position: absolute;
  font-family: 'Oswald', sans-serif;
  font-size: 20px;
  text-decoration: none;
}

@media (min-width: 429px) {
  .cmn-gl_nav--corp_name {
    bottom: 20px;
    right: 30px;
    writing-mode: vertical-rl;
  }
}

@media (max-width: 428px) {
  .cmn-gl_nav--corp_name {
    display: none;
  }
}

.cmn-gl_nav--corp_name_sp {
  display: none;
}

@media (max-width: 428px) {
  .cmn-gl_nav--corp_name_sp {
    position: absolute;
    display: block;
    top: 16px;
    left: 16px;
  }
}

.cmn-gl_nav--corp_name_logo {
  width: 210px;
}

.cmn-gl_nav--content {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 20px 60px;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  pointer-events: none;
  opacity: 0;
}

@media (max-width: 428px) {
  .cmn-gl_nav--content {
    padding: 40px 20px 0 20px;
  }
}

.cmn-gl_nav--link {
  opacity: 0;
  color: #fff;
  font-family: 'Oswald', sans-serif;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  margin: 0 0 25px 0;
  pointer-events: none;
  left: -10px;
}

.cmn-gl_nav--link:last-child {
  margin-bottom: 0;
}

.cmn-gl_nav--link:hover {
  color: #39A796;
}

.cmn-gl_nav--link:nth-child(1) {
  transition: left 1s 0.19s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s 0.19s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cmn-gl_nav--link:nth-child(2) {
  transition: left 1s 0.28s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s 0.28s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cmn-gl_nav--link:nth-child(3) {
  transition: left 1s 0.37s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s 0.37s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cmn-gl_nav--link:nth-child(4) {
  transition: left 1s 0.46s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s 0.46s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.cmn-gl_nav--link:nth-child(5) {
  transition: left 1s 0.55s cubic-bezier(0.23, 1, 0.32, 1), opacity 1s 0.55s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 428px) {
  .cmn-gl_nav--link {
    font-size: 20px;
    text-align: center;
  }
  .background_gray_sp .cmn-gl_nav--link {
    color: #000;
  }
}

.cmn-content {
  overflow: hidden;
}

.cmn-content.is_show .cmn-content--topic-outside {
  opacity: 1;
}

.cmn-content.is_show .cmn-content--title_text {
  opacity: 1;
  top: 0;
}

.cmn-content.is_show .cmn-content--catch {
  opacity: 1;
  top: 0;
}

.cmn-content.is_show .js-separate_text {
  opacity: 1;
}

.cmn-content.is_show .js-separate_text--text:nth-child(1) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.02s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.02s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(2) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.04s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.04s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(3) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.06s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.06s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(4) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.08s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.08s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(5) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.1s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.1s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(6) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.12s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.12s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(7) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.14s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.14s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(8) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.16s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.16s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(9) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.18s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.18s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(10) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.2s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.2s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(11) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.22s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.22s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(12) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.24s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.24s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(13) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.26s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.26s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(14) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.28s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.28s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(15) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.3s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.3s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(16) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.32s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.32s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(17) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.34s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.34s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(18) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.36s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.36s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(19) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.38s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.38s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(20) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.4s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.4s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(21) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.42s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.42s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(22) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.44s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.44s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(23) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.46s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.46s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(24) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.48s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.48s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(25) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.5s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.5s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(26) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.52s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.52s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(27) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.54s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.54s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(28) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.56s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.56s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(29) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.58s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.58s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(30) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.6s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.6s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(31) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.62s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.62s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(32) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.64s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.64s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(33) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.66s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.66s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(34) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.68s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.68s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(35) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.7s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.7s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(36) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.72s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.72s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(37) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.74s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.74s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(38) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.76s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.76s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(39) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.78s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.78s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(40) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.8s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.8s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(41) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.82s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.82s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(42) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.84s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.84s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(43) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.86s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.86s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(44) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.88s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.88s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(45) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.9s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.9s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(46) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.92s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.92s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(47) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.94s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.94s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(48) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.96s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.96s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(49) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.98s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.98s;
}

.cmn-content.is_show .js-separate_text--text:nth-child(50) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1s;
}

.cmn-content--topics {
  position: relative;
  font-family: 'Oswald', sans-serif;
  font-size: 140px;
  letter-spacing: 6px;
  text-shadow: 1px 1px 0 #707070, -1px -1px 0 #707070, -1px 1px 0 #707070, 1px -1px 0 #707070, 0px 1px 0 #707070, -1px 0 #707070, -1px 0 0 #707070, 1px 0 0 #707070;
  font-weight: 400;
  padding: 0 5px 5px 0;
}

@media (min-width: 429px) {
  .cmn-content--topics {
    margin: 0 25px 0 0;
  }
}

@media (max-width: 428px) {
  .cmn-content--topics {
    font-size: 60px;
    margin-bottom: 15px;
  }
}

.cmn-content--topic-inner {
  position: relative;
  color: #D6EBD9;
  z-index: 1;
}

.cmn-content--topic-outside {
  position: absolute;
  left: 5px;
  top: 5px;
  color: #fff;
  opacity: 0;
  transition: 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (max-width: 428px) {
  .cmn-content--topic-outside {
    left: 3px;
    top: 3px;
  }
}

.cmn-content--title {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  margin: 0 0 90px 0;
  position: relative;
}

@media (max-width: 428px) {
  .cmn-content--title {
    margin-bottom: 60px;
  }
}

@media (min-width: 429px) {
  .cmn-content--title-n1 {
    left: -15px;
  }
}

@media (min-width: 429px) {
  .cmn-content--title-n2 {
    left: -35px;
  }
}

@media (min-width: 429px) {
  .cmn-content--title-n3 {
    left: -175px;
  }
}

@media (min-width: 429px) {
  .cmn-content--title-n4 {
    left: -135px;
  }
}

@media (min-width: 429px) {
  .cmn-content--title-n5 {
    left: -135px;
  }
}

.cmn-content--title_text {
  color: #39A796;
  font-size: 48px;
  text-stroke: 1px #707070;
  -webkit-text-stroke: 1px #707070;
  line-height: 1.4;
  opacity: 0;
  position: relative;
  top: 10px;
  transition: 0.9s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.cmn-content--title_text-center {
  text-align: center;
  margin-top: 60px;
}

@media (max-width: 428px) {
  .cmn-content--title_text-center {
    margin-top: 0;
  }
}

@media (max-width: 428px) {
  .cmn-content--title_text {
    font-size: 22px;
    text-align: center;
  }
}

.cmn-content--catch {
  font-size: 32px;
  font-weight: bold;
  line-height: 1.5;
  top: 10px;
  opacity: 0;
  transition: 0.9s 0.45s cubic-bezier(0.23, 1, 0.32, 1);
  position: relative;
}

@media (max-width: 428px) {
  .cmn-content--catch {
    font-size: 18px;
    display: block;
    width: 100%;
    text-align: center;
    margin: 0 0 15px 0;
  }
  .cmn-content--catch:last-child {
    margin-bottom: 0;
  }
}

.cmn-content--list_item {
  line-height: 1.8;
  text-indent: -1em;
  padding: 0 0 0 1em;
}

.cmn-cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 100;
  pointer-events: none;
  display: block;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 2px solid #39A796;
  opacity: 0;
}

.cmn-cursor:before {
  content: '';
  display: block;
  position: absolute;
  width: inherit;
  height: inherit;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  animation: fadeCursor 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
}

@media (max-width: 428px) {
  .cmn-cursor {
    display: none;
  }
}

.cmn-cursor.is_clicked {
  background: #39A796;
  width: 10px;
  height: 10px;
}

.background {
  background: #fff;
}

.main_content {
  overflow: hidden;
  width: 100%;
  min-height: 763px;
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 70px;
  box-sizing: border-box;
}

.main_content:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  z-index: 10;
  transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media (max-width: 428px) {
  .main_content {
    min-height: 500px;
    padding-top: 10px;
    padding-left: 0;
  }
}

.main_content.is_show:after {
  width: 0;
}

.main_content.is_show .main_content--logo {
  opacity: 1;
}

.main_content.is_show .main_content--logo_car {
  left: 80px;
  opacity: 1;
}

@media (max-width: 428px) {
  .main_content.is_show .main_content--logo_car {
    left: 44px;
  }
}

.main_content.is_show .main_content--anchor_button {
  top: 0;
  opacity: 1;
}

.main_content.is_show .js-separate_text {
  opacity: 1;
}

.main_content.is_show .js-separate_text--text:nth-child(1) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.72s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.72s;
}

.main_content.is_show .js-separate_text--text:nth-child(2) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.74s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.74s;
}

.main_content.is_show .js-separate_text--text:nth-child(3) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.76s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.76s;
}

.main_content.is_show .js-separate_text--text:nth-child(4) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.78s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.78s;
}

.main_content.is_show .js-separate_text--text:nth-child(5) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.8s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.8s;
}

.main_content.is_show .js-separate_text--text:nth-child(6) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.82s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.82s;
}

.main_content.is_show .js-separate_text--text:nth-child(7) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.84s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.84s;
}

.main_content.is_show .js-separate_text--text:nth-child(8) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.86s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.86s;
}

.main_content.is_show .js-separate_text--text:nth-child(9) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.88s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.88s;
}

.main_content.is_show .js-separate_text--text:nth-child(10) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.9s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.9s;
}

.main_content.is_show .js-separate_text--text:nth-child(11) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.92s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.92s;
}

.main_content.is_show .js-separate_text--text:nth-child(12) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.94s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.94s;
}

.main_content.is_show .js-separate_text--text:nth-child(13) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.96s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.96s;
}

.main_content.is_show .js-separate_text--text:nth-child(14) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 0.98s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 0.98s;
}

.main_content.is_show .js-separate_text--text:nth-child(15) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1s;
}

.main_content.is_show .js-separate_text--text:nth-child(16) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.02s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.02s;
}

.main_content.is_show .js-separate_text--text:nth-child(17) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.04s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.04s;
}

.main_content.is_show .js-separate_text--text:nth-child(18) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.06s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.06s;
}

.main_content.is_show .js-separate_text--text:nth-child(19) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.08s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.08s;
}

.main_content.is_show .js-separate_text--text:nth-child(20) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.1s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.1s;
}

.main_content.is_show .js-separate_text--text:nth-child(21) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.12s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.12s;
}

.main_content.is_show .js-separate_text--text:nth-child(22) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.14s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.14s;
}

.main_content.is_show .js-separate_text--text:nth-child(23) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.16s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.16s;
}

.main_content.is_show .js-separate_text--text:nth-child(24) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.18s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.18s;
}

.main_content.is_show .js-separate_text--text:nth-child(25) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.2s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.2s;
}

.main_content.is_show .js-separate_text--text:nth-child(26) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.22s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.22s;
}

.main_content.is_show .js-separate_text--text:nth-child(27) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.24s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.24s;
}

.main_content.is_show .js-separate_text--text:nth-child(28) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.26s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.26s;
}

.main_content.is_show .js-separate_text--text:nth-child(29) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.28s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.28s;
}

.main_content.is_show .js-separate_text--text:nth-child(30) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.3s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.3s;
}

.main_content.is_show .js-separate_text--text:nth-child(31) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.32s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.32s;
}

.main_content.is_show .js-separate_text--text:nth-child(32) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.34s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.34s;
}

.main_content.is_show .js-separate_text--text:nth-child(33) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.36s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.36s;
}

.main_content.is_show .js-separate_text--text:nth-child(34) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.38s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.38s;
}

.main_content.is_show .js-separate_text--text:nth-child(35) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.4s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.4s;
}

.main_content.is_show .js-separate_text--text:nth-child(36) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.42s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.42s;
}

.main_content.is_show .js-separate_text--text:nth-child(37) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.44s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.44s;
}

.main_content.is_show .js-separate_text--text:nth-child(38) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.46s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.46s;
}

.main_content.is_show .js-separate_text--text:nth-child(39) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.48s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.48s;
}

.main_content.is_show .js-separate_text--text:nth-child(40) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.5s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.5s;
}

.main_content.is_show .js-separate_text--text:nth-child(41) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.52s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.52s;
}

.main_content.is_show .js-separate_text--text:nth-child(42) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.54s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.54s;
}

.main_content.is_show .js-separate_text--text:nth-child(43) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.56s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.56s;
}

.main_content.is_show .js-separate_text--text:nth-child(44) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.58s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.58s;
}

.main_content.is_show .js-separate_text--text:nth-child(45) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.6s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.6s;
}

.main_content.is_show .js-separate_text--text:nth-child(46) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.62s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.62s;
}

.main_content.is_show .js-separate_text--text:nth-child(47) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.64s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.64s;
}

.main_content.is_show .js-separate_text--text:nth-child(48) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.66s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.66s;
}

.main_content.is_show .js-separate_text--text:nth-child(49) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.68s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.68s;
}

.main_content.is_show .js-separate_text--text:nth-child(50) {
  transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
  opacity: 1;
  transition: transform 0.6s 1.7s cubic-bezier(0.08, 0.92, 0.35, 1), opacity 0.55s 1.7s;
}

.main_content--grey_background {
  display: block;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.main_content--logo {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  opacity: 0;
  transition: 1.2s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (max-width: 428px) {
  .main_content--logo {
    width: 100%;
  }
}

.main_content--logo_car {
  position: absolute;
  left: 120px;
  opacity: 0;
  top: 223px;
  z-index: 1;
  transition: 1.3s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

@media (max-width: 428px) {
  .main_content--logo_car {
    width: 188px;
    top: 157px;
  }
}

.main_content--logo_company {
  position: absolute;
  top: 32px;
  right: 40px;
  z-index: 1;
}

@media (max-width: 428px) {
  .main_content--logo_company {
    display: none;
  }
}

.main_content--logo_area {
  width: 440px;
  height: 440px;
  position: relative;
}

@media (max-width: 428px) {
  .main_content--logo_area {
    width: 300px;
    height: 300px;
  }
}

.main_content--title {
  margin: 0 0 40px 0;
  font-size: 32px;
  line-height: 1.4;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  text-align: center;
  position: relative;
  z-index: 1;
}

.main_content--anchor_button {
  font-family: 'Oswald', sans-serif;
  font-size: 12px;
  font-weight: 100;
  width: 80px;
  height: 80px;
  border: 1px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.3);
  text-decoration: none;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.9s 1s cubic-bezier(0.23, 1, 0.32, 1), opacity 1.1s 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  opacity: 0;
  top: -10px;
}

.main_content--anchor_button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.main_content--anchor {
  display: block;
  height: 27px;
  width: 1px;
  background: #fff;
  position: relative;
  margin: 0 0 10px 0;
  animation: loopSlideBottom 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
}

.main_content--anchor:before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  display: block;
  bottom: 0;
  left: -5px;
}

.detail_content {
  background: url("/images/cmn-background-border.png");
  position: relative;
}

.detail_content--background {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  left: 110px;
  top: 40px;
  width: calc(100% - 150px);
  height: calc(100% - 40px);
}

@media (max-width: 428px) {
  .detail_content--background {
    left: 20px;
    top: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 20px);
  }
}

.detail_content--inner {
  max-width: 980px;
  padding: 140px 0 140px 70px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 428px) {
  .detail_content--inner {
    padding-left: 0;
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.detail_content--box {
  display: flex;
  margin: 0 0 140px 0;
}

.detail_content--box:last-child {
  margin-bottom: 0;
}

@media (max-width: 428px) {
  .detail_content--box {
    margin-bottom: 80px;
    display: block;
  }
}

.detail_content--box.is_show .detail_content--picture {
  top: 0;
  opacity: 1;
}

.detail_content--box.is_show .detail_content--illustration {
  top: 0;
  opacity: 1;
}

.detail_content--box.is_show .detail_content--description {
  top: 0;
  opacity: 1;
}

.detail_content--box-reverse {
  flex-flow: row-reverse;
}

.detail_content--picture {
  margin-left: -200px;
  position: relative;
  top: 30px;
  opacity: 0;
  transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 428px) {
  .detail_content--picture {
    width: 100%;
    height: auto;
    margin: 0 0 -20px 0;
  }
}

@media (min-width: 429px) {
  .detail_content--picture-reverse {
    margin: 0 -200px 0 0;
  }
}

@media (min-width: 429px) {
  .detail_content--box_content {
    margin: 0 0 0 40px;
  }
}

.detail_content--illustration {
  position: relative;
  top: 30px;
  opacity: 0;
  transition: 0.8s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 428px) {
  .detail_content--illustration {
    width: calc(100% - 80px);
    margin: 0 auto 30px auto;
    display: block;
    height: auto;
  }
}

@media (min-width: 429px) {
  .detail_content--illustration-n1 {
    margin: -40px 0 30px -70px;
  }
}

@media (min-width: 429px) {
  .detail_content--illustration-n2 {
    margin: 10px -50px 30px 0px;
  }
}

@media (min-width: 429px) {
  .detail_content--illustration-n3 {
    margin: 40px 0 30px -70px;
  }
}

.detail_content--description {
  font-size: 20px;
  line-height: 2.0;
  font-weight: bold;
  position: relative;
  top: 30px;
  opacity: 0;
  transition: 0.8s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 428px) {
  .detail_content--description {
    width: calc(100% - 80px);
    margin: 0 auto;
    font-size: 16px;
  }
  .detail_content--description br {
    display: none;
  }
}

.aside_content {
  background: url("/images/cmn-background-border.png");
  position: relative;
  overflow: hidden;
}

.aside_content--background_area {
  background: url("/images/aside_content--background_area.jpg") no-repeat bottom center;
  background-size: cover;
  width: 100%;
  height: 550px;
}

@media (max-width: 428px) {
  .aside_content--background_area {
    height: 300px;
  }
}

.aside_content--description_background {
  position: absolute;
  left: calc(50% - 499px);
  top: 470px;
}

@media (max-width: 428px) {
  .aside_content--description_background-pc {
    display: none;
  }
}

.aside_content--description_background-sp {
  display: none;
}

@media (max-width: 428px) {
  .aside_content--description_background-sp {
    display: block;
    left: calc(50% - 262px);
    top: 227px;
    width: 524px;
    height: auto;
  }
}

.aside_content--description {
  font-size: 20px;
  line-height: 2.2;
  font-weight: bold;
  text-align: center;
  min-height: 390px;
  box-sizing: border-box;
  padding: 20px 0 0 0;
  position: relative;
  z-index: 1;
}

@media (max-width: 428px) {
  .aside_content--description {
    min-height: 340px;
    font-size: 16px;
    padding-top: 0;
  }
}

.what_content {
  padding: 80px 0 140px 70px;
  max-width: 980px;
  margin: 0 auto;
}

@media (max-width: 428px) {
  .what_content {
    padding-bottom: 80px;
    padding-left: 0;
  }
}

.what_content--box {
  display: flex;
  margin: 0 0 65px 0;
}

.what_content--box.is_show .what_content--picture {
  opacity: 1;
  top: 0;
}

.what_content--box.is_show .what_content--description {
  opacity: 1;
  top: 0;
}

@media (max-width: 428px) {
  .what_content--box {
    display: block;
    margin-bottom: 30px;
  }
}

.what_content--picture {
  max-width: 100%;
  height: auto;
  opacity: 0;
  position: relative;
  top: 10px;
  transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (min-width: 429px) {
  .what_content--picture {
    margin: 0 0 0 -200px;
  }
}

@media (max-width: 428px) {
  .what_content--picture {
    margin: 0 0 30px 0;
  }
}

.what_content--description {
  font-weight: bold;
  font-size: 16px;
  line-height: 2.0;
  opacity: 0;
  top: 10px;
  position: relative;
  transition: 0.8s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (min-width: 429px) {
  .what_content--description {
    margin: 0 0 0 40px;
  }
}

@media (max-width: 428px) {
  .what_content--description {
    margin: 0 auto;
    width: calc(100% - 40px);
  }
}

.what_content--features {
  display: flex;
  justify-content: space-between;
}

.what_content--features.is_show .what_content--features_box {
  opacity: 1;
  top: 0;
}

@media (max-width: 428px) {
  .what_content--features {
    display: block;
  }
}

.what_content--features_box {
  opacity: 0;
  top: 10px;
  position: relative;
}

.what_content--features_box:nth-child(1) {
  transition: 0.8s 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.what_content--features_box:nth-child(2) {
  transition: 0.8s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.what_content--features_box:nth-child(3) {
  transition: 0.8s 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

@media (max-width: 428px) {
  .what_content--features_box {
    display: flex;
    width: calc(100% - 40px);
    margin: 0 auto 15px;
    align-items: center;
  }
  .what_content--features_box:last-child {
    margin-bottom: 0;
  }
}

.what_content--features_illustration {
  display: block;
}

@media (min-width: 429px) {
  .what_content--features_illustration {
    margin: 0 0 25px 0;
  }
}

@media (max-width: 428px) {
  .what_content--features_illustration {
    width: 100px;
  }
}

.what_content--features_title {
  font-size: 24px;
  line-height: 1.5;
  text-align: center;
}

@media (max-width: 428px) {
  .what_content--features_title {
    flex: 1;
    margin: 0 0 0 15px;
    text-align: left;
    font-size: 18px;
  }
  .what_content--features_title br {
    display: none;
  }
}

.what_content--logo_title {
  font-size: 32px;
  min-height: 95px;
  background: url("/images/cmn-background-border.png");
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 40px 0;
}

@media (max-width: 428px) {
  .what_content--logo_title {
    font-size: 22px;
    min-height: 60px;
  }
}

.what_content--logo_illustration {
  display: block;
  margin: 0 auto 20px auto;
}

@media (max-width: 428px) {
  .what_content--logo_illustration {
    width: 80%;
    height: auto;
  }
}

.what_content--logo_description {
  font-weight: bold;
  font-size: 16px;
  line-height: 2.0;
}

@media (min-width: 429px) {
  .what_content--logo_description {
    text-align: center;
  }
}

@media (max-width: 428px) {
  .what_content--logo_description {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
  .what_content--logo_description br {
    display: none;
  }
}

.what_content_products_link {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 56px auto 0;
  max-width: 864px;
  min-height: 170px;
  background-image: url("/images/what_content_products_link-bgimg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 85px;
  text-decoration: none;
  box-shadow: 8px 8px 0 #213326;
  position: relative;
  transition: 0.5s;
}

.what_content_products_link:hover {
  box-shadow: none;
}

.what_content_products_link:before, .what_content_products_link:after {
  transition: inherit;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.what_content_products_link:hover:before, .what_content_products_link:hover:after {
  opacity: 1;
}

.what_content_products_link:before {
  z-index: 1;
  background-image: linear-gradient(#000000, #000000);
  mix-blend-mode: color;
  border-radius: 85px;
}

.what_content_products_link:after {
  z-index: 2;
  background-image: linear-gradient(#BFFFD1, #BFFFD1);
  mix-blend-mode: multiply;
  border-radius: 85px;
}

@media (max-width: 428px) {
  .what_content_products_link {
    transition: 0.2s;
    max-width: 80%;
    min-height: 80px;
  }
}

.what_content_products_link--inner {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
  position: relative;
  z-index: 10;
}

.what_content_products_link--products {
  position: relative;
  font-family: 'Oswald', sans-serif;
  font-size: 80px;
  font-weight: 400;
  padding: 0 5px 5px 0;
}

@media (max-width: 428px) {
  .what_content_products_link--products {
    font-size: 24px;
  }
}

.what_content_products_link--products > span:first-child {
  position: relative;
  color: #fff;
  z-index: 1;
}

.what_content_products_link--products > span:last-child {
  position: absolute;
  left: 5px;
  top: 5px;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
}

@media (max-width: 428px) {
  .what_content_products_link--products > span:last-child {
    left: 3px;
    top: 3px;
  }
}

.what_content_products_link--text {
  line-height: 1.5;
  font-weight: bold;
  font-size: 32px;
  color: #fff;
}

@media (max-width: 428px) {
  .what_content_products_link--text {
    font-size: 24px;
  }
}

.outline_content {
  background: url("/images/cmn-background-border.png");
  position: relative;
}

.outline_content--background {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 40px;
  height: calc(100% - 80px);
  left: 110px;
  width: calc(100% - 150px);
}

@media (max-width: 428px) {
  .outline_content--background {
    left: 20px;
    top: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}

.outline_content--inner {
  max-width: 980px;
  padding: 80px 0 140px 70px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 428px) {
  .outline_content--inner {
    padding-bottom: 20px;
    padding-left: 0;
  }
}

.outline_content--box {
  padding: 20px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
}

.outline_content--box:nth-of-type(1) {
  border-top: 1px solid #000;
}

@media (min-width: 429px) {
  .outline_content--box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 150px;
  }
}

@media (max-width: 428px) {
  .outline_content--box {
    width: calc(100% - 40px);
    margin: 0 auto;
    border-color: #ccc;
    padding: 40px 20px;
  }
  .outline_content--box:nth-of-type(1) {
    border-top-color: #ccc;
  }
  .outline_content--box:last-child {
    border-bottom: 0;
    padding-bottom: 80px;
  }
}

.outline_content--box_title {
  font-size: 24px;
  box-sizing: border-box;
}

@media (min-width: 429px) {
  .outline_content--box_title {
    min-width: 200px;
  }
}

@media (max-width: 428px) {
  .outline_content--box_title {
    text-align: center;
    margin: 0 0 15px 0;
    font-size: 18px;
  }
}

.outline_content--box_description {
  line-height: 1.8;
}

@media (min-width: 429px) {
  .outline_content--box_description {
    width: calc(100% - 200px);
  }
}

.access_content {
  padding: 80px 0 140px 70px;
  padding-bottom: 0;
}

@media (max-width: 428px) {
  .access_content {
    padding-left: 0;
    padding-bottom: 80px;
    padding-bottom: 0;
  }
}

.access_content--map {
  max-width: 950px;
  margin: 0 auto;
}

@media (min-width: 429px) {
  .access_content--map {
    display: flex;
    justify-content: space-between;
  }
}

.access_content--map_iframe {
  width: 500px;
  height: 500px;
}

@media (max-width: 428px) {
  .access_content--map_iframe {
    width: 100%;
    height: 300px;
    margin: 0 0 30px 0;
  }
}

@media (min-width: 429px) {
  .access_content--map_content {
    padding: 10px 0;
  }
}

@media (max-width: 428px) {
  .access_content--map_content {
    width: calc(100% - 40px);
    margin: 0 auto;
  }
}

.access_content--map_header {
  border-bottom: 1px solid #000;
  padding: 0 0 30px 0;
  margin: 0 0 30px 0;
}

.access_content--map_title {
  font-family: 'Oswald', sans-serif;
  font-size: 60px;
  margin: 0 0 15px 0;
  line-height: 1.2;
}

@media (max-width: 428px) {
  .access_content--map_title {
    font-size: 35px;
  }
}

.access_content--map_jp_name {
  font-size: 24px;
  font-weight: bold;
}

@media (max-width: 428px) {
  .access_content--map_jp_name {
    font-size: 18px;
  }
}

.access_content--map_address {
  margin: 0 0 30px 0;
  line-height: 1.5;
}

.access_content--map_tel {
  font-size: 48px;
  font-family: 'Oswald', sans-serif;
  margin: 0 0 15px 0;
}

.access_content--map_tel a {
  color: #363636;
}

@media (max-width: 428px) {
  .access_content--map_tel {
    font-size: 30px;
  }
}

.access_content--map_fax {
  font-size: 24px;
  font-family: 'Oswald', sans-serif;
  margin: 0 0 30px 0;
}

.access_content--map_fax a {
  color: #363636;
}

@media (max-width: 428px) {
  .access_content--map_fax {
    font-size: 30px;
  }
}

.access_content--map_access {
  line-height: 1.8;
  text-indent: -1em;
  padding: 0 0 0 1em;
}

.access_content--map_access:first-letter {
  color: #39A796;
}

.contact_content {
  background: url("/images/contact_content-background.png") repeat-x bottom center, #EAFCED;
  position: relative;
  margin-top: 160px;
}

@media (max-width: 428px) {
  .contact_content {
    margin-top: 80px;
  }
}

.contact_content--background {
  background: rgba(255, 255, 255, 0.95);
  position: absolute;
  top: 40px;
  height: calc(100% - 220px);
  left: 110px;
  width: calc(100% - 150px);
}

@media (max-width: 428px) {
  .contact_content--background {
    left: 20px;
    top: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }
}

.contact_content--inner {
  max-width: 980px;
  padding: 80px 0 270px 70px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

@media (max-width: 428px) {
  .contact_content--inner {
    padding-left: 0;
    padding-bottom: 90px;
  }
}

@media (max-width: 428px) {
  .contact_content--form {
    width: calc(100% - 80px);
    margin: 0 auto;
  }
}

.contact_content--form_box {
  box-sizing: border-box;
  margin: 0 0 25px 0;
}

@media (min-width: 429px) {
  .contact_content--form_box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .contact_content--form_box:nth-of-type(5) {
    margin-bottom: 60px;
  }
}

@media (max-width: 428px) {
  .contact_content--form_box {
    margin-bottom: 30px;
  }
}

@media (min-width: 429px) {
  .contact_content--form_right {
    flex: 1;
    margin: 0 0 0 20px;
  }
}

.contact_content--form_box_title {
  font-size: 18px;
  box-sizing: border-box;
}

@media (min-width: 429px) {
  .contact_content--form_box_title {
    min-width: 200px;
    text-align: right;
  }
}

@media (max-width: 428px) {
  .contact_content--form_box_title {
    margin: 0 0 15px 0;
    display: block;
    font-size: 16px;
  }
}

.contact_content--form_radio_box {
  background: #F5F5F5;
  padding: 20px;
  font-size: 20px;
}

@media (min-width: 429px) {
  .contact_content--form_radio_box {
    display: flex;
  }
}

@media (max-width: 428px) {
  .contact_content--form_radio_box {
    padding: 15px;
  }
}

.contact_content--form_radio_row {
  position: relative;
  cursor: pointer;
}

.contact_content--form_radio_row:first-child {
  margin-right: 20px;
}

@media (max-width: 428px) {
  .contact_content--form_radio_row {
    margin: 0 0 15px 0;
  }
  .contact_content--form_radio_row:last-child {
    margin-bottom: 0;
  }
}

.contact_content--form_box_radio {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #959EA7;
  -webkit-appearance: none;
  margin: 0;
  position: absolute;
  left: 0;
  top: -5px;
}

.contact_content--form_box_radio:before {
  content: '';
  display: block;
  position: absolute;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  width: 14px;
  height: 14px;
  background: green;
  border-radius: 50%;
  transform: scale(0, 0);
  transition: 0.15s;
}

.contact_content--form_box_radio:checked:before {
  transform: scale(1, 1);
}

@media (max-width: 428px) {
  .contact_content--form_box_radio {
    width: 20px;
    height: 20px;
    top: 2px;
  }
}

.contact_content--form_label {
  padding: 0 0 0 40px;
}

@media (max-width: 428px) {
  .contact_content--form_label {
    font-size: 16px;
    padding: 0 0 0 30px;
  }
}

.contact_content--form_input {
  width: 100%;
  border: 1px solid #707070;
  font-size: 16px;
  height: 50px;
  padding: 10px;
  box-sizing: border-box;
  transition: 0.15s;
  -webkit-appearance: none;
  border-radius: 0;
}

.contact_content--form_input::placeholder {
  color: #f1f1f1;
}

.contact_content--form_input:focus {
  box-shadow: 0 0 5px #d6ebd9;
  border: 1px solid #39A796;
}

.contact_content--form_textarea {
  width: 100%;
  border: 1px solid #000;
  display: block;
  font-size: 16px;
  padding: 10px;
  box-sizing: border-box;
  transition: 0.15s;
  -webkit-appearance: none;
  border-radius: 0;
}

.contact_content--form_textarea::placeholder {
  color: #f1f1f1;
}

.contact_content--form_textarea:focus {
  box-shadow: 0 0 5px #d6ebd9;
  border: 1px solid #39A796;
}

.contact_content--form_submit {
  background: #39A796;
  color: #fff;
  -webkit-appearance: none;
  border-radius: 0;
  border: 0;
  max-width: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 77px;
  margin: 0 auto;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.contact_content--form_submit:hover {
  background: #2c8174;
}

@media (max-width: 428px) {
  .contact_content--form_submit {
    height: 50px;
    font-size: 18px;
  }
}

.sent_mail_content {
  opacity: 0;
  pointer-events: none;
  transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  z-index: 101;
}

.sent_mail_content.is_show {
  opacity: 1;
  pointer-events: auto;
}

.sent_mail_content.is_show .sent_mail_content--content {
  opacity: 1;
}

.sent_mail_content--background {
  position: fixed;
  z-index: 100;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.sent_mail_content--content {
  background: #fff;
  width: 800px;
  position: fixed;
  left: calc(50% - 400px);
  top: 150px;
  z-index: 101;
  padding: 80px 40px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
}

@media (max-width: 428px) {
  .sent_mail_content--content {
    left: 20px;
    width: calc(100% - 40px);
    top: 30px;
    padding: 30px;
    box-sizing: border-box;
  }
}

.sent_mail_content--title {
  color: #39A796;
  font-size: 30px;
  line-height: 1.4;
  position: relative;
  text-align: center;
  margin: 0 0 30px 0;
  font-family: 'Noto Sans JP', sans-serif;
}

@media (max-width: 428px) {
  .sent_mail_content--title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 15px;
  }
}

.sent_mail_content--description {
  font-weight: bold;
  font-size: 16px;
  line-height: 2.0;
  position: relative;
  text-align: center;
  margin: 0 0 40px 0;
  font-family: 'Noto Sans JP', sans-serif;
}

@media (max-width: 428px) {
  .sent_mail_content--description {
    margin: 0 auto 30px auto;
    width: 100%;
    text-align: left;
  }
}

.sent_mail_content--button {
  text-decoration: none;
  width: 300px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #39A796;
  margin: 0 auto;
  transition: 0.15s;
  cursor: pointer;
}

.sent_mail_content--button:hover {
  background: #2c8174;
}

@media (max-width: 428px) {
  .sent_mail_content--button {
    width: 100%;
  }
}

.js-separate_text {
  opacity: 0;
}

.js-separate_text--text {
  position: relative;
  display: inline-block;
  opacity: 0;
}

.js-separate_text--text:nth-child(1) {
  transform: scale(1.3) translate3d(5px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(2) {
  transform: scale(1.3) translate3d(10px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(3) {
  transform: scale(1.3) translate3d(15px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(4) {
  transform: scale(1.3) translate3d(20px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(5) {
  transform: scale(1.3) translate3d(25px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(6) {
  transform: scale(1.3) translate3d(30px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(7) {
  transform: scale(1.3) translate3d(35px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(8) {
  transform: scale(1.3) translate3d(40px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(9) {
  transform: scale(1.3) translate3d(45px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(10) {
  transform: scale(1.3) translate3d(50px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(11) {
  transform: scale(1.3) translate3d(55px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(12) {
  transform: scale(1.3) translate3d(60px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(13) {
  transform: scale(1.3) translate3d(65px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(14) {
  transform: scale(1.3) translate3d(70px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(15) {
  transform: scale(1.3) translate3d(75px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(16) {
  transform: scale(1.3) translate3d(80px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(17) {
  transform: scale(1.3) translate3d(85px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(18) {
  transform: scale(1.3) translate3d(90px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(19) {
  transform: scale(1.3) translate3d(95px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(20) {
  transform: scale(1.3) translate3d(100px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(21) {
  transform: scale(1.3) translate3d(105px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(22) {
  transform: scale(1.3) translate3d(110px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(23) {
  transform: scale(1.3) translate3d(115px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(24) {
  transform: scale(1.3) translate3d(120px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(25) {
  transform: scale(1.3) translate3d(125px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(26) {
  transform: scale(1.3) translate3d(130px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(27) {
  transform: scale(1.3) translate3d(135px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(28) {
  transform: scale(1.3) translate3d(140px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(29) {
  transform: scale(1.3) translate3d(145px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(30) {
  transform: scale(1.3) translate3d(150px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(31) {
  transform: scale(1.3) translate3d(155px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(32) {
  transform: scale(1.3) translate3d(160px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(33) {
  transform: scale(1.3) translate3d(165px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(34) {
  transform: scale(1.3) translate3d(170px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(35) {
  transform: scale(1.3) translate3d(175px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(36) {
  transform: scale(1.3) translate3d(180px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(37) {
  transform: scale(1.3) translate3d(185px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(38) {
  transform: scale(1.3) translate3d(190px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(39) {
  transform: scale(1.3) translate3d(195px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(40) {
  transform: scale(1.3) translate3d(200px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(41) {
  transform: scale(1.3) translate3d(205px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(42) {
  transform: scale(1.3) translate3d(210px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(43) {
  transform: scale(1.3) translate3d(215px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(44) {
  transform: scale(1.3) translate3d(220px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(45) {
  transform: scale(1.3) translate3d(225px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(46) {
  transform: scale(1.3) translate3d(230px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(47) {
  transform: scale(1.3) translate3d(235px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(48) {
  transform: scale(1.3) translate3d(240px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(49) {
  transform: scale(1.3) translate3d(245px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--text:nth-child(50) {
  transform: scale(1.3) translate3d(250px, 50px, 0) skewX(60deg) rotateX(-80deg);
}

.js-separate_text--space {
  display: inline-block;
  margin: 0 19px;
}

@media (max-width: 428px) {
  .js-separate_text--space {
    margin: 0 10px;
  }
}

.js-separate_text--br {
  display: block;
}

@keyframes fadeCursor {
  from {
    opacity: 1;
    transform: scale(1, 1);
  }
  to {
    opacity: 0;
    transform: scale(2, 2);
  }
}

@keyframes loopSlideBottom {
  from {
    bottom: 10px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideRightNav {
  from {
    left: -80px;
  }
  to {
    left: 0;
  }
}
