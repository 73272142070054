@charset "UTF-8";
@import "mixin";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP:400,700,900|Oswald:300,400&display=swap');
$colorGreen: #39A796;
html {
  scroll-behavior: smooth;
}
:root {
  --navigation_width_pc: 75px;
  --navigation_height_sp: 60px;
}
* {
  &::selection {
    background: $colorGreen;
    color: #fff;
  }
}
body {
  font-family: 'Noto Sans JP', sans-serif;
  overflow: auto;
  background: #fff;
  min-width: 1280px;
  &.is_loading {
    overflow: hidden;
  }
  @include sp {
    min-width: 100%;
  }
}
canvas {
  position: absolute;
  left: 0;
  top: 0;
}
.pc {
  display: block;
  @include sp {
    display: none;
  }
}
.sp {
  display: none;
  @include sp {
    display: block;
  }
}
.post-edit-link {
  line-height: 1.5;
}
.cmn-contents {
  font-family: 'Noto Sans JP', sans-serif;
  color: #363636;
  &--copy {
    background: #213326;
    color: #fff;
    font-family: 'Oswald', sans-serif;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--top_button {
    position: fixed;
    right: 20px;
    bottom: 0;
    width: 80px;
    height: 80px;
    border: 2px solid #fff;
    border-radius: 10px;
    background: #363636;
    z-index: 10;
    opacity: 0;
    transition: 0.9s cubic-bezier(0.23, 1, 0.32, 1);
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 2.5px);
      top: calc(50% - 17.5px);
      width: 5px;
      height: 35px;
      background: #fff;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border-top: 5px solid #fff;
      border-right: 5px solid #fff;
      transform: rotate(-45deg);
      left: calc(50% - 12px);
      top: 25px;
    }
    &.is_show {
      bottom: 20px;
      opacity: 1;
    }
    @include sp {
      width: 50px;
      height: 50px;
      right: 10px;
      bottom: 10px;
      &:before {
        left: calc(50% - 1px);
        top: calc(50% - 10px);
        width: 2px;
        height: 20px;
        background: #fff;
      }
      &:after {
        width: 10px;
        height: 10px;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(-45deg);
        left: calc(50% - 6px);
        top: 15px;
      }
    }
  }
}
.cmn-gl_nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: #213326;
  width: 75px;
  z-index: 10;
  transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  @include pc {
    left: -80px;
  }
  @include sp {
    width: 100%;
    height: 60px;
  }
  &.is_animate_show {
    @include pc {
      animation: slideRightNav 0.9s 0.95s cubic-bezier(0.23, 1, 0.32, 1) forwards;
    }
  }
  &.show_on_start {
    @include pc {
      left: 0;
    }
  }
  &.is_show {
    @include pc {
      width: 300px;
    }
    @include sp {
      height: 350px;
    }
    .cmn-gl_nav {
      &--menu_button {
        @include pc {
          &:before {
            transform: rotate(-212deg) skew(0, -20deg);
            left: 8px;
          }
        }
        @include sp {
          &:before {
            transform: rotate(-126deg) skew(0, -20deg);
            top: 9px;
          }
        }
      }
      &--content {
        opacity: 1;
        pointer-events: auto;
      }
      &--link {
        pointer-events: auto;
        opacity: 1;
        left: 0;
      }
    }
  }
  &.background_gray_sp {
    @include sp {
      background-color: #dddddd;
    }
  }
  &--header {
  }
  &--menu_button {
    font-family: 'Oswald', sans-serif;
    font-size: 14px;
    font-weight: 100;
    text-decoration: none;
    color: #fff;
    position: absolute;
    right: 25px;
    z-index: 10;
    &:before {
      content: '';
      display: block;
      height: 25px;
      width: 25px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(-32deg) skew(0deg, -20deg);
      position: relative;
      left: -5px;
      margin: 9px 0 20px 0;
      transition: 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    }
    @include pc {
      top: calc(50% - 35px);
    }
    @include sp {
      width: 70px;
      text-align: center;
      letter-spacing: 2px;
      right: 0;
      height: 60px;
      padding: 5px 0 0 0;
      box-sizing: border-box;
      .background_gray_sp & {
        color: #000000;
      }
      &:before {
        transform: rotate(54deg) skew(0deg, -20deg);
        left: 26px;
        width: 15px;
        height: 15px;
        margin: 0 0 10px 0;
      }
      .background_gray_sp &:before {
        border-color: #000000;
      }
    }
  }
  &--corp_name {
    color: #fff;
    position: absolute;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    text-decoration: none;
    @include pc {
      bottom: 20px;
      right: 30px;
      writing-mode: vertical-rl;
    }
    @include sp {
      display: none;
    }
  }
  &--corp_name_sp {
    display: none;
    @include sp {
      position: absolute;
      display: block;
      top: 16px;
      left: 16px;
    }
  }
  &--corp_name_logo {
    width: 210px;
  }
  &--content {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    padding: 20px 60px;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    pointer-events: none;
    opacity: 0;
    @include sp {
      padding: 40px 20px 0 20px;
    }
  }
  &--link {
    opacity: 0;
    color: #fff;
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    font-weight: bold;
    position: relative;
    text-decoration: none;
    margin: 0 0 25px 0;
    pointer-events: none;
    left: -10px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: $colorGreen;
    }
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        transition: left 1.0s #{$i * 0.09 + 0.1}s cubic-bezier(0.23, 1, 0.32, 1), opacity 1.0s #{$i * 0.09 + 0.1}s cubic-bezier(0.23, 1, 0.32, 1), color 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
    @include sp {
      font-size: 20px;
      text-align: center;
      .background_gray_sp & {
        color: #000;
      }
    }
  }
}
.cmn-content {
  overflow: hidden;
  &.is_show {
    .cmn-content {
      &--topic {
        &-outside {
          opacity: 1;
        }
      }
      &--title_text {
        opacity: 1;
        top: 0;
      }
      &--catch {
        opacity: 1;
        top: 0;
      }
    }
    .js-separate_text {
      opacity: 1;
      &--text {
        @for $i from 1 through 50 {
          &:nth-child(#{$i}) {
            transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
            opacity: 1;
            transition: transform 0.6s #{$i * 0.02}s cubic-bezier(.08, .92, .35, 1), opacity 0.55s #{$i * 0.02}s;
          }
        }
      }
    }
  }
  &--topics {
    position: relative;
    font-family: 'Oswald', sans-serif;
    font-size: 140px;
    letter-spacing: 6px;
    text-shadow: 1px 1px 0 #707070, -1px -1px 0 #707070,
    -1px 1px 0 #707070, 1px -1px 0 #707070,
    0px 1px 0 #707070, 0-1px 0 #707070,
    -1px 0 0 #707070, 1px 0 0 #707070;
    font-weight: 400;
    padding: 0 5px 5px 0;
    @include pc {
      margin: 0 25px 0 0;
    }
    @include sp {
      font-size: 60px;
      margin-bottom: 15px;
    }
  }
  &--topic {
    &-inner {
      position: relative;
      color: #D6EBD9;
      z-index: 1;
    }
    &-outside {
      position: absolute;
      left: 5px;
      top: 5px;
      color: #fff;
      opacity: 0;
      transition: 0.8s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
      @include sp {
        left: 3px;
        top: 3px;
      }
    }
  }
  &--title {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    margin: 0 0 90px 0;
    position: relative;
    @include sp {
      margin-bottom: 60px;
    }
    &-n1 {
      @include pc {
        left: -15px;
      }
    }
    &-n2 {
      @include pc {
        left: -35px;
      }
    }
    &-n3 {
      @include pc {
        left: -175px;
      }
    }
    &-n4 {
      @include pc {
        left: -135px;
      }
    }
    &-n5 {
      @include pc {
        left: -135px;
      }
    }
  }
  &--title_text {
    color: $colorGreen;
    font-size: 48px;
    text-stroke: 1px #707070;
    -webkit-text-stroke: 1px #707070;
    line-height: 1.4;
    opacity: 0;
    position: relative;
    top: 10px;
    transition: 0.9s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    &-center {
      text-align: center;
      margin-top: 60px;
      @include sp {
        margin-top: 0;
      }
    }
    @include sp {
      font-size: 22px;
      text-align: center;
    }
  }
  &--catch {
    font-size: 32px;
    font-weight: bold;
    line-height: 1.5;
    top: 10px;
    opacity: 0;
    transition: 0.9s 0.45s cubic-bezier(0.23, 1, 0.32, 1);
    position: relative;
    @include sp {
      font-size: 18px;
      display: block;
      width: 100%;
      text-align: center;
      margin: 0 0 15px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--list {
  }
  &--list_item {
    line-height: 1.8;
    text-indent: -1em;
    padding: 0 0 0 1em;
  }
}
.cmn-cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  z-index: 100;
  pointer-events: none;
  display: block;
  transition: 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  border: 2px solid $colorGreen;
  opacity: 0;
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    animation: fadeCursor 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  @include sp {
    display: none;
  }
  &.is_clicked {
    background: $colorGreen;
    width: 10px;
    height: 10px;
  }
}
.background {
  background: #fff;
}
.main_content {
  overflow: hidden;
  width: 100%;
  min-height: 763px;
  height: 100vh;
  //background: url('/images/main_content-background.png') no-repeat bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
  align-content: center;
  justify-items: center;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  position: relative;
  padding: 0 0 0 70px;
  box-sizing: border-box;
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 10;
    transition: 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  @include sp {
    min-height: 500px;
    padding-top: 10px;
    padding-left: 0;
    //background: url('/images/main_content-background.png') no-repeat bottom right;
  }
  &.is_show {
    &:after {
      width: 0;
    }
    .main_content {
      &--logo {
        opacity: 1;
      }
      &--logo_car {
        left: 80px;
        opacity: 1;
        @include sp {
          left: 44px;
        }
      }
      &--anchor_button {
        top: 0;
        opacity: 1;
      }
    }
    .js-separate_text {
      opacity: 1;
      &--text {
        @for $i from 1 through 50 {
          &:nth-child(#{$i}) {
            transform: scale(1) translate3d(0, 0, 0) skewX(0deg) rotateX(0deg);
            opacity: 1;
            transition: transform 0.6s #{$i * 0.02 + 0.7}s cubic-bezier(.08, .92, .35, 1), opacity 0.55s #{$i * 0.02 + 0.7}s;
          }
        }
      }
    }
  }
  &--grey_background {
    display: block;
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
  }
  &--logo {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    transition: 1.2s 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    @include sp {
      width: 100%;
    }
  }
  &--logo_car {
    position: absolute;
    left: 120px;
    opacity: 0;
    top: 223px;
    z-index: 1;
    transition: 1.3s 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    @include sp {
      width: 188px;
      top: 157px;
    }
  }
  &--logo_company {
    position: absolute;
    top: 32px;
    right: 40px;
    z-index: 1;
    @include sp {
      display: none;
    }
  }
  &--logo_area {
    width: 440px;
    height: 440px;
    position: relative;
    @include sp {
      width: 300px;
      height: 300px;
    }
  }
  &--title {
    margin: 0 0 40px 0;
    font-size: 32px;
    line-height: 1.4;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &--anchor_button {
    font-family: 'Oswald', sans-serif;
    font-size: 12px;
    font-weight: 100;
    width: 80px;
    height: 80px;
    border: 1px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.3);
    text-decoration: none;
    display: flex;
    align-items: center;
    align-content: center;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    transition: background 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.9s 1.0s cubic-bezier(0.23, 1, 0.32, 1), opacity 1.1s 0.8s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
    top: -10px;
    &:hover {
      background: rgba(0, 0, 0, 0.8);
    }
  }
  &--anchor {
    display: block;
    height: 27px;
    width: 1px;
    background: #fff;
    position: relative;
    margin: 0 0 10px 0;
    animation: loopSlideBottom 1.4s cubic-bezier(0.645, 0.045, 0.355, 1) infinite;
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: rotate(45deg);
      display: block;
      bottom: 0;
      left: -5px;
    }
  }
}
.detail_content {
  background: url('/images/cmn-background-border.png');
  position: relative;
  &--background {
    background: rgba(255, 255, 255, 0.95);
    position: absolute;
    left: 110px;
    top: 40px;
    width: calc(100% - 150px);
    height: calc(100% - 40px);
    @include sp {
      left: 20px;
      top: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 20px);
    }
  }
  &--inner {
    max-width: 980px;
    padding: 140px 0 140px 70px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include sp {
      padding-left: 0;
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }
  &--box {
    display: flex;
    margin: 0 0 140px 0;
    &:last-child {
      margin-bottom: 0;
    }
    @include sp {
      margin-bottom: 80px;
      display: block;
    }
    &.is_show {
      .detail_content {
        &--picture {
          top: 0;
          opacity: 1;
        }
        &--illustration {
          top: 0;
          opacity: 1;
        }
        &--description {
          top: 0;
          opacity: 1;
        }
      }
    }
    &-reverse {
      flex-flow: row-reverse;
    }
  }
  &--picture {
    margin-left: -200px;
    position: relative;
    top: 30px;
    opacity: 0;
    transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include sp {
      width: 100%;
      height: auto;
      margin: 0 0 -20px 0;
    }
    &-reverse {
      @include pc {
        margin: 0 -200px 0 0;
      }
    }
  }
  &--box_content {
    @include pc {
      margin: 0 0 0 40px;
    }
  }
  &--illustration {
    position: relative;
    top: 30px;
    opacity: 0;
    transition: 0.8s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include sp {
      width: calc(100% - 80px);
      margin: 0 auto 30px auto;
      display: block;
      height: auto;
    }
    &-n1 {
      @include pc {
        margin: -40px 0 30px -70px;
      }
    }
    &-n2 {
      @include pc {
        margin: 10px -50px 30px 0px;
      }
    }
    &-n3 {
      @include pc {
        margin: 40px 0 30px -70px;
      }
    }
  }
  &--description {
    font-size: 20px;
    line-height: 2.0;
    font-weight: bold;
    position: relative;
    top: 30px;
    opacity: 0;
    transition: 0.8s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include sp {
      width: calc(100% - 80px);
      margin: 0 auto;
      font-size: 16px;
      br {
        display: none;
      }
    }
  }
}
.aside_content {
  background: url('/images/cmn-background-border.png');
  position: relative;
  overflow: hidden;
  &--background_area {
    background: url('/images/aside_content--background_area.jpg') no-repeat bottom center;
    background-size: cover;
    width: 100%;
    height: 550px;
    @include sp {
      height: 300px;
    }
  }
  &--description_background {
    position: absolute;
    left: calc(50% - 499px);
    top: 470px;
    &-pc {
      @include sp {
        display: none;
      }
    }
    &-sp {
      display: none;
      @include sp {
        display: block;
        left: calc(50% - 262px);
        top: 227px;
        width: 524px;
        height: auto;
      }
    }
  }
  &--description {
    font-size: 20px;
    line-height: 2.2;
    font-weight: bold;
    text-align: center;
    min-height: 390px;
    box-sizing: border-box;
    padding: 20px 0 0 0;
    position: relative;
    z-index: 1;
    @include sp {
      min-height: 340px;
      font-size: 16px;
      padding-top: 0;
    }
  }
}
.what_content {
  padding: 80px 0 140px 70px;
  max-width: 980px;
  margin: 0 auto;
  @include sp {
    padding-bottom: 80px;
    padding-left: 0;
  }
  &--box {
    display: flex;
    margin: 0 0 65px 0;
    &.is_show {
      .what_content {
        &--picture {
          opacity: 1;
          top: 0;
        }
        &--description {
          opacity: 1;
          top: 0;
        }
      }
    }
    @include sp {
      display: block;
      margin-bottom: 30px;
    }
  }
  &--picture {
    max-width: 100%;
    height: auto;
    opacity: 0;
    position: relative;
    top: 10px;
    transition: 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include pc {
      margin: 0 0 0 -200px;
    }
    @include sp {
      margin: 0 0 30px 0;
    }
  }
  &--description {
    font-weight: bold;
    font-size: 16px;
    line-height: 2.0;
    opacity: 0;
    top: 10px;
    position: relative;
    transition: 0.8s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    @include pc {
      margin: 0 0 0 40px;
    }
    @include sp {
      margin: 0 auto;
      width: calc(100% - 40px);
    }
  }
  &--features {
    display: flex;
    justify-content: space-between;
    &.is_show {
      .what_content {
        &--features_box {
          opacity: 1;
          top: 0;
        }
      }
    }
    @include sp {
      display: block;
    }
  }
  &--features_box {
    opacity: 0;
    top: 10px;
    position: relative;
    @for $i from 1 through 3 {
      &:nth-child(#{$i}) {
        transition: 0.8s #{$i * 0.1}s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
    @include sp {
      display: flex;
      width: calc(100% - 40px);
      margin: 0 auto 15px;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--features_illustration {
    display: block;
    @include pc {
      margin: 0 0 25px 0;
    }
    @include sp {
      width: 100px;
    }
  }
  &--features_title {
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    @include sp {
      flex: 1;
      margin: 0 0 0 15px;
      text-align: left;
      font-size: 18px;
      br {
        display: none;
      }
    }
  }
  &--logo {
  }
  &--logo_title {
    font-size: 32px;
    min-height: 95px;
    background: url('/images/cmn-background-border.png');
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 40px 0;
    @include sp {
      font-size: 22px;
      min-height: 60px;
    }
  }
  &--logo_illustration {
    display: block;
    margin: 0 auto 20px auto;
    @include sp {
      width: 80%;
      height: auto;
    }
  }
  &--logo_description {
    font-weight: bold;
    font-size: 16px;
    line-height: 2.0;
    @include pc {
      text-align: center;
    }
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto;
      br {
        display: none;
      }
    }
  }
}
.what_content_products_link {
  $br: 85px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  margin: 56px auto 0;
  max-width: 864px;
  min-height: 170px;
  background-image: url('/images/what_content_products_link-bgimg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: $br;
  text-decoration: none;
  box-shadow: 8px 8px 0 #213326;
  position: relative;
  transition: 0.5s;
  &:hover {
    box-shadow: none;
  }
  &:before,&:after {
    transition: inherit;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: '';
  }
  &:hover:before,&:hover:after {
    opacity: 1;
  }
  &:before {
    z-index: 1;
    background-image: linear-gradient(#000000, #000000);
    mix-blend-mode: color;
    border-radius: $br;
  }
  &:after {
    z-index: 2;
    background-image: linear-gradient(#BFFFD1, #BFFFD1);
    mix-blend-mode: multiply;
    border-radius: $br;
  }
  @include sp {
    transition: 0.2s;
    max-width: 80%;
    min-height: 80px;
  }
  &--inner {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 24px;
    position: relative;
    z-index: 10;
  }
  &--products {
    position: relative;
    font-family: 'Oswald', sans-serif;
    font-size: 80px;
    font-weight: 400;
    padding: 0 5px 5px 0;
    @include sp {
      font-size: 24px;
    }
    & > span:first-child {
      position: relative;
      color: #fff;
      z-index: 1;
    }
    & > span:last-child {
      position: absolute;
      left: 5px;
      top: 5px;
      color: transparent;
      -webkit-text-stroke: 1px #fff;
      @include sp {
        left: 3px;
        top: 3px;
      }
    }
  }
  &--text {
    line-height: 1.5;
    font-weight: bold;
    font-size: 32px;
    color: #fff;
    @include sp {
      font-size: 24px;
    }
  }
}
.outline_content {
  background: url('/images/cmn-background-border.png');
  position: relative;
  &--background {
    background: rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 40px;
    height: calc(100% - 80px);
    left: 110px;
    width: calc(100% - 150px);
    @include sp {
      left: 20px;
      top: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
    }
  }
  &--inner {
    max-width: 980px;
    padding: 80px 0 140px 70px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include sp {
      padding-bottom: 20px;
      padding-left: 0;
    }
  }
  &--box {
    padding: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
    &:nth-of-type(1) {
      border-top: 1px solid #000;
    }
    @include pc {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      min-height: 150px;
    }
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto;
      border-color: #ccc;
      padding: 40px 20px;
      &:nth-of-type(1) {
        border-top-color: #ccc;
      }
      &:last-child {
        border-bottom: 0;
        padding-bottom: 80px;
      }
    }
  }
  &--box_title {
    font-size: 24px;
    box-sizing: border-box;
    @include pc {
      min-width: 200px;
    }
    @include sp {
      text-align: center;
      margin: 0 0 15px 0;
      font-size: 18px;
    }
  }
  &--box_description {
    line-height: 1.8;
    @include pc {
      width: calc(100% - 200px);
    }
  }
}
.access_content {
  padding: 80px 0 140px 70px;
  padding-bottom: 0;
  @include sp {
    padding-left: 0;
    padding-bottom: 80px;
    padding-bottom: 0;
  }
  &--map {
    max-width: 950px;
    margin: 0 auto;
    @include pc {
      display: flex;
      justify-content: space-between;
    }
  }
  &--map_iframe {
    width: 500px;
    height: 500px;
    @include sp {
      width: 100%;
      height: 300px;
      margin: 0 0 30px 0;
    }
  }
  &--map_content {
    @include pc {
      padding: 10px 0;
    }
    @include sp {
      width: calc(100% - 40px);
      margin: 0 auto;
    }
  }
  &--map_header {
    border-bottom: 1px solid #000;
    padding: 0 0 30px 0;
    margin: 0 0 30px 0;
  }
  &--map_title {
    font-family: 'Oswald', sans-serif;
    font-size: 60px;
    margin: 0 0 15px 0;
    line-height: 1.2;
    @include sp {
      font-size: 35px;
    }
  }
  &--map_jp_name {
    font-size: 24px;
    font-weight: bold;
    @include sp {
      font-size: 18px;
    }
  }
  &--map_address {
    margin: 0 0 30px 0;
    line-height: 1.5;
  }
  &--map_tel {
    font-size: 48px;
    font-family: 'Oswald', sans-serif;
    margin: 0 0 15px 0;
    a {
      color: #363636;
    }
    @include sp {
      font-size: 30px;
    }
  }
  &--map_fax {
    font-size: 24px;
    font-family: 'Oswald', sans-serif;
    margin: 0 0 30px 0;
    a {
      color: #363636;
    }
    @include sp {
      font-size: 30px;
    }
  }
  &--map_access {
    line-height: 1.8;
    text-indent: -1em;
    padding: 0 0 0 1em;
    &:first-letter {
      color: $colorGreen;
    }
  }
}
.contact_content {
  background: url('/images/contact_content-background.png') repeat-x bottom center, #EAFCED;
  position: relative;
  margin-top: 160px;
  @include sp {
    margin-top: 80px;
  }
  &--background {
    background: rgba(255, 255, 255, 0.95);
    position: absolute;
    top: 40px;
    height: calc(100% - 220px);
    left: 110px;
    width: calc(100% - 150px);
    @include sp {
      left: 20px;
      top: 20px;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
    }
  }
  &--inner {
    max-width: 980px;
    padding: 80px 0 270px 70px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    @include sp {
      padding-left: 0;
      padding-bottom: 90px;
    }
  }
  &--form {
    @include sp {
      width: calc(100% - 80px);
      margin: 0 auto;
    }
  }
  &--form_box {
    box-sizing: border-box;
    margin: 0 0 25px 0;
    @include pc {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      &:nth-of-type(5) {
        margin-bottom: 60px;
      }
    }
    @include sp {
      margin-bottom: 30px;
    }
  }
  &--form_right {
    @include pc {
      flex: 1;
      margin: 0 0 0 20px;
    }
  }
  &--form_box_title {
    font-size: 18px;
    box-sizing: border-box;
    @include pc {
      min-width: 200px;
      text-align: right;
    }
    @include sp {
      margin: 0 0 15px 0;
      display: block;
      font-size: 16px;
    }
  }
  &--form_radio_box {
    background: #F5F5F5;
    padding: 20px;
    font-size: 20px;
    @include pc {
      display: flex;
    }
    @include sp {
      padding: 15px;
    }
  }
  &--form_radio_row {
    position: relative;
    cursor: pointer;
    &:first-child {
      margin-right: 20px;
    }
    @include sp {
      margin: 0 0 15px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &--form_box_radio {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #959EA7;
    -webkit-appearance: none;
    margin: 0;
    position: absolute;
    left: 0;
    top: -5px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: calc(50% - 7px);
      top: calc(50% - 7px);
      width: 14px;
      height: 14px;
      background: green;
      border-radius: 50%;
      transform: scale(0, 0);
      transition: 0.15s;
    }
    &:checked {
      &:before {
        transform: scale(1, 1);
      }
    }
    @include sp {
      width: 20px;
      height: 20px;
      top: 2px;
    }
  }
  &--form_label {
    padding: 0 0 0 40px;
    @include sp {
      font-size: 16px;
      padding: 0 0 0 30px;
    }
  }
  &--form_input {
    width: 100%;
    border: 1px solid #707070;
    font-size: 16px;
    height: 50px;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.15s;
    -webkit-appearance: none;
    border-radius: 0;
    &::placeholder {
      color: #f1f1f1;
    }
    &:focus {
      box-shadow: 0 0 5px rgba(214, 235, 217, 1.0);
      border: 1px solid $colorGreen;
    }
  }
  &--form_textarea {
    width: 100%;
    border: 1px solid #000;
    display: block;
    font-size: 16px;
    padding: 10px;
    box-sizing: border-box;
    transition: 0.15s;
    -webkit-appearance: none;
    border-radius: 0;
    &::placeholder {
      color: #f1f1f1;
    }
    &:focus {
      box-shadow: 0 0 5px rgba(214, 235, 217, 1.0);
      border: 1px solid $colorGreen;
    }
  }
  &--form_submit {
    background: $colorGreen;
    color: #fff;
    -webkit-appearance: none;
    border-radius: 0;
    border: 0;
    max-width: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 77px;
    margin: 0 auto;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    &:hover {
      background: darken($colorGreen, 10%);
    }
    @include sp {
      height: 50px;
      font-size: 18px;
    }
  }
}
.sent_mail_content {
  opacity: 0;
  pointer-events: none;
  transition: 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  z-index: 101;
  &.is_show {
    opacity: 1;
    pointer-events: auto;
    .sent_mail_content {
      &--content {
        opacity: 1;
      }
    }
  }
  &--background {
    position: fixed;
    z-index: 100;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &--content {
    background: #fff;
    width: 800px;
    position: fixed;
    left: calc(50% - 400px);
    top: 150px;
    z-index: 101;
    padding: 80px 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.4s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    opacity: 0;
    @include sp {
      left: 20px;
      width: calc(100% - 40px);
      top: 30px;
      padding: 30px;
      box-sizing: border-box;
    }
  }
  &--title {
    color: $colorGreen;
    font-size: 30px;
    line-height: 1.4;
    position: relative;
    text-align: center;
    margin: 0 0 30px 0;
    font-family: 'Noto Sans JP', sans-serif;
    @include sp {
      font-size: 22px;
      text-align: center;
      margin-bottom: 15px;
    }
  }
  &--description {
    font-weight: bold;
    font-size: 16px;
    line-height: 2.0;
    position: relative;
    text-align: center;
    margin: 0 0 40px 0;
    font-family: 'Noto Sans JP', sans-serif;
    @include sp {
      margin: 0 auto 30px auto;
      width: 100%;
      text-align: left;
    }
  }
  &--button {
    text-decoration: none;
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: $colorGreen;
    margin: 0 auto;
    transition: 0.15s;
    cursor: pointer;
    &:hover {
      background: darken($colorGreen, 10%);
    }
    @include sp {
      width: 100%;
    }
  }
}
.js-separate_text {
  opacity: 0;
  &--text {
    position: relative;
    display: inline-block;
    opacity: 0;
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transform: scale(1.3) translate3d(#{$i * 5}px, 50px, 0) skewX(60deg) rotateX(-80deg);
      }
    }
  }
  &--space {
    display: inline-block;
    margin: 0 19px;
    @include sp {
      margin: 0 10px;
    }
  }
  &--br {
    display: block;
  }
}
@keyframes fadeCursor {
  from {
    opacity: 1;
    transform: scale(1, 1);
  }
  to {
    opacity: 0;
    transform: scale(2, 2);
  }
}
@keyframes loopSlideBottom {
  from {
    bottom: 10px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes slideRightNav {
  from {
    left: -80px;
  }
  to {
    left: 0;
  }
}
